import React from 'react';
import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import { OrderControlContext } from 'modules/orders/index';
import messages from 'messages';
import { useApi } from 'providers';
import { Color } from 'interfaces';
import { Control } from 'components';
import { AggregatedRequirementIdSchema, OrderRequirement } from 'interfaces/api';
import { keys, pick } from 'lodash';

const labels = messages.orders.controls.cancelRequirement;

type Props = OrderControlContext & {
  requirement: OrderRequirement;
};

export const CancelRequirement: React.FC<Props> = (props) => {

  const { data, requirement, context: { selectedOrder }, bindings: { reload } } = props;
  const order = data.orders[selectedOrder || 0];

  const { orders: { cancelOrderRequirement } } = useApi();

  return (
    <Control
      label={labels.label}
      confirm={labels.confirm}
      notifications={labels.notifications}
      icon={{ icon: faTimesCircle, color: Color.Red }}
      onClick={async (event) => {
        event.preventDefault();
        await cancelOrderRequirement({ orderId: order.id, requirement: pick(requirement, keys(AggregatedRequirementIdSchema)) });
        reload();
      }}
    />
  );
};

import { AggregatedProfileProperties, OrderBasketFormProperties, OrderSample, OrderWizardRequirement } from 'interfaces/api';
import { MissingRequirementOption } from 'modules/orders/interfaces';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { createSelectors } from 'utils/helpers';

export type BasketPending = { requirement: OrderWizardRequirement; missing: MissingRequirementOption };

interface BasketPropsState {
  pending: BasketPending;
  showRules: OrderWizardRequirement[];
  formGroups: OrderBasketFormProperties[];
  orderSamples: OrderSample[];
  basketProfiles: AggregatedProfileProperties[];
}

interface BasketSetState {
  setPending: (pending: BasketPending) => void;
  setShowRules: (showRules: OrderWizardRequirement[]) => void;
  setFormGroups: (formGroups: OrderBasketFormProperties[]) => void;
  setOrderSamples: (orderSamples: OrderSample[]) => void;
  setBasketProfiles: (basketProfiles: AggregatedProfileProperties[]) => void;
}

const initialState: BasketPropsState = {
  pending: undefined,
  showRules: undefined,
  formGroups: [],
  orderSamples: [],
  basketProfiles: [],
};

export const useBasketStore = create<BasketPropsState & BasketSetState>()(devtools((set, get) => ({
  ...initialState,
  reset: () => set(initialState),
  setPending: pending => set({ pending }),
  setShowRules: showRules => set({ showRules }),
  setFormGroups: formGroups => set({ formGroups }),
  setOrderSamples: orderSamples => set({ orderSamples }),
  setBasketProfiles: basketProfiles => set({ basketProfiles }),
})));

export const useBasketSelectors = createSelectors(useBasketStore).use;

import {
  AccountingArea,
  BloodCollectionType,
  BookmarkedMode,
  ChTreatmentReason,
  CompletionListOrderBy,
  DmpSpecialGroup,
  DmpType,
  ExceptionalIndication,
  InsuranceType,
  MicrobiologicalLeftRight,
  OrderChangeAction,
  OrderDigitalSignature,
  OrderListMode,
  OrderReason,
  OrderRuleActionType,
  OrderRulesMandatoryField,
  OrderStatus,
  RealReRequestMode,
  RequirementAggregationType,
  WorkstationStatus,
} from 'interfaces/api';

export default {
  listHeading: 'Order List',
  mobileTitle: 'Order Detail',
  autocomplete: {
    default: 'Text search',
    groups: {
      requirements: 'Requirements',
      filters: 'Groupings',
    },
    close: 'Reset Selection',
  },
  orderNumber: 'Order #{number}',
  orderForm: 'Form',
  formType: ' Form Type',
  create: 'Create Order',
  asNewOrder: 'As new order',
  executeAsNewOrder: 'Execute again',
  edit: 'Edit Order',
  requirements: 'Requirements',
  requirementsAggregated: 'Requirements by {GROUP}',
  realReRequestedAt: 'Re-requested at {date}',
  realReRequestComment: 'Comment: {comment}',
  dashboard: 'Dashboard',
  samples: 'Samples',
  reprintedSamples: 'Reprinted',
  sampleType: 'Sample Type',
  chooseDoctor: 'Choose doctor',
  officeDoctor: 'Office doctor',
  chooseDoctorOffice: 'Choose doctor office',
  autoAssignLanrBsnrFail: 'Could not find doctor/LANR/BSNR. Please select manually.',
  showAll: 'Show all {COUNT}',
  toggleFavorite: 'Toggle favorite',
  addToBasket: 'Add to basket',
  removeFromBasket: 'Remove from basket',
  instantExecution: 'Execute instantly',
  printHints: 'Print note',
  totalSelected: '{orders, plural, one {# order} other {# orders}} selected',
  totalSamplesSelected: '{orders, plural, one {# order} other {# orders}} with {total, plural, one {# sample} other {# samples}} total',
  exttnrFound: 'An order with exttnr <b>{exttnr}</b> has been found',
  notScheduled: 'Not scheduled',
  groupBy: {
    placeholder: 'Group By',
    values: {
      forms: 'Order forms',
      materials: 'Materials',
    },
  },
  sortBy: {
    placeholder: 'Sort By',
    values: {
      order: 'As in the order',
      name: 'Alphabetical',
    },
  },
  statusBar: {
    initialized: 'Initialized',
    inExecution: 'In Execution',
    executed: 'Executed',
    execution: 'Execution',
    prePrint: 'Pre-printed',
    transport: 'Transport',
    transported: 'Transported',
    report: 'Report',
    follows: 'follows',
  },
  status: {
    [OrderStatus.Canceled]: 'Canceled',
    [OrderStatus.Incorrect]: 'Incorrect',
    [OrderStatus.ExportErrorLDT]: 'LDT Export Error',
    [OrderStatus.Saved]: 'Saved',
    [OrderStatus.Queued]: 'Queued',
    [OrderStatus.Executed]: 'Executed',
    [OrderStatus.Processing]: 'Processing',
    [OrderStatus.PrePrint]: 'Pre Print',
    [OrderStatus.Amendment]: 'Amendment',
    [OrderStatus.AmendmentQueued]: 'Amendment Queued',
    [OrderStatus.AmendmentApproved]: 'Amendment Approved',
    [OrderStatus.PreExecution]: 'Pre Execution',
  },
  isPool: 'Pool Order',
  isSplit: 'Split Order',
  hasReportCopyInfo: 'Report copy',
  digitalSignature: {
    [OrderDigitalSignature.NotDefined]: 'Not Defined',
    [OrderDigitalSignature.Signed]: 'Signed',
    [OrderDigitalSignature.Unsigned]: 'Unsigned',
    [OrderDigitalSignature.None]: 'None',
  },
  mode: {
    title: 'Mode Selection',
    home: 'All Orders',
  },
  modes: {
    [OrderListMode.Bookmarked]: 'Bookmarked',
    [OrderListMode.DigitalSignature]: 'Digital Signature',
    [OrderListMode.Transport]: 'Transport',
    [OrderListMode.Completion]: 'Completion',
    [OrderListMode.Journal]: 'Journal',
    [OrderListMode.MassOrders]: 'Mass Orders',
    [OrderListMode.RealReRequest]: 'Real re-requests',
  },
  editPatient: 'Edit patient',
  selectPatient: 'Select patient',
  scheduled_at: 'Scheduled at',
  created_at: 'Created at',
  executed_at: 'Executed at',
  sampled_at: 'Sampled at',
  chooseOrder: 'Choose order',
  chooseOrders: 'Choose orders',
  filters: {
    status: 'Order Status',
    digitalSignature: 'Digital Signature',
    bookmarkedModes: {
      label: 'Selection',
      [BookmarkedMode.Today]: 'Today',
      [BookmarkedMode.Coming]: 'Coming',
      [BookmarkedMode.Old]: 'Old',
      [BookmarkedMode.Custom]: 'Custom',
      scheduledFrom: 'Scheduled from',
      scheduledTo: 'Scheduled to',
    },
    realReRequestModes: {
      label: 'View Mode',
      [RealReRequestMode.Applied]: 'Applied',
      [RealReRequestMode.History]: 'History',
    },
    completionOrder: {
      [CompletionListOrderBy.OrderDate]: 'Order date',
      [CompletionListOrderBy.ScheduleDate]: 'Schedule date',
    },
    sampleInLabFilter: {
      label: 'Sample in lab',
      only: 'Show only sampled in lab',
      not: 'Show only not sampled in lab',
    },
    poolFilter: {
      label: 'Pool orders',
      only: 'Show only pool orders',
      not: 'Show only not pool orders',
    },
    poctFilter: {
      label: 'Poct orders',
      only: 'Show only poct orders',
      not: 'Show only not poct orders',
    },
  },
  workstations: {
    selectLabel: 'Select Workstation',
    status: {
      [WorkstationStatus.Deactivated]: 'Deactivated',
      [WorkstationStatus.ConnectionError]: 'ConnectionError',
      [WorkstationStatus.Connected]: 'Connected',
      [WorkstationStatus.Disconnected]: 'Offline',
      [WorkstationStatus.Registration]: 'Registration',
    },
    local: 'Alias',
  },
  gdtError: {
    title: 'Gdt Import failed',
    content: 'The patient was not found',
  },
  transportation: {
    total: 'Total',
    history: 'History',
    summary: 'Summary',
    execute: {
      label: 'Execute now',
      confirm: {
        title: 'Execute transportation',
        content: 'Are you sure you want to mark all selected orders as executed?',
      },
      notifications: {
        success: 'Executed transportation list successfully',
      },
    },
    export: 'Export PDF',
  },
  completion: {
    complete: {
      label: 'Complete now',
      confirm: {
        title: 'Complete orders',
        content: 'Are you sure you want to complete the selected orders?',
      },
      notifications: {
        success: 'Completed successfully',
      },
    },
  },
  sign: {
    label: 'Sign now',
    notifications: {
      success: 'Signed successfully',
    },
  },
  patients: {
    select: 'Select',
    selectPatients: 'Select Patients',
    selectAll: 'Select all',
    edit: 'Edit patient',
    scan: 'Scan Patient QR Code',
    scanPatient: 'Scan Patient',
    pool: {
      label: '{COUNT} patients',
      selected: {
        all: 'All selected',
        some: '{COUNT} selected',
      },
    },
  },
  patientReports: 'Patient reports',
  userSelect: {
    label: 'Select Users',
    tabs: {
      lis: 'LIS',
      ais: 'AIS',
      kis: 'KIS',
      groups: 'Patient groups',
      manual: 'Manual',
    },
  },
  requirementAggregations: {
    types: {
      [RequirementAggregationType.Name]: 'Name',
      [RequirementAggregationType.Category]: 'Categories',
      [RequirementAggregationType.Indication]: 'Indications',
      [RequirementAggregationType.OrderForm]: 'Order Forms',
      [RequirementAggregationType.Materials]: 'Materials',
      [RequirementAggregationType.ProfilesOnly]: 'Profile',
      [RequirementAggregationType.GroupFormCategory]: 'Group Form Category',
      [RequirementAggregationType.Requirement]: 'Requirement',
      [RequirementAggregationType.Analysis]: 'Analyses',
    },
    unassigned: 'Unassigned',
    profiles: 'Profiles',
    favorites: 'Favorites',
    topRequests: 'My Top 50',
    microbiological: 'BAK',
    allResults: 'All requirements',
    noResults: 'No requirements found',
    pinnedOnly: 'Show pinned only',
    topOnly: 'Show my top 50',
    bakOnly: 'Show BAK only',
  },
  wizard: {
    formCostUnitFormat: '{name} ({costUnit})',
    title: 'Order Wizard',
    specifications: 'Specifications',
    sideBar: {
      directInput: {
        placeholder: 'Input',
        toggle: 'Toggle direct input/search mode',
        notFound: 'No requirement found',
      },
      settings: {
        title: 'Settings',
        startFilters: {
          title: 'Dashboard Filters',
        },
        tabs: {
          general: 'General',
          profiles: 'Profiles',
          groupForms: 'Group forms',
          dashboard: 'Dashboard',
        },
        compactView: 'Compact View',
        ultraCompactView: {
          label: 'Ultra Compact View',
          description: 'Paddings and font sizes will be decreased even more',
        },
        additionalTextInPopover: {
          label: 'Show additional text in popover',
          description: '',
        },
        additionalTextInCompactView: {
          label: 'Show additional text in compact view',
          description: '',
        },
        hideBakMaterialGroupInfo: {
          label: 'Hide MiBi group forms material group Info',
          description: 'If the compact view is activated, the MiBi request material is no longer displayed in the group certificate view',
        },
        profiles: 'Profiles',
        groupForms: 'Group forms',
        disablePopovers: 'Disable Popovers',
        disableTopRequests: 'Disable "Top 50" view',
        disableAllPopovers: 'Disable also profiles popovers',
        enableBasketPopovers: 'Enable basket popovers',
        startGroups: {
          title: 'Start in group form',
          noCostUnit: 'No cost unit',
          none: 'None (Home Screen)',
        },
        profilesInGroupForm: 'Show profiles in group forms',
        favoritesInGroupForm: 'Show favorites in group forms',
        topRequestsInGroupForm: 'Show top requests in group forms',
        wrapPopoverHeader: 'Multi line popover headers',
        unwrapHeader: 'Multi line list headers',
        unwrapListTitle: 'Multi line list title',
        widerColumns: 'Less columns in view',
        disableMasonryView: 'Disable masonry view',
        defaultInvoiceTo: 'Default invoice to',
      },
    },
    mode: {
      profile: 'Profiles',
      individual: 'Individual',
    },
    groupBy: {
      label: 'Group by',
      selected: 'Selected',
      available: 'Available',
    },
    basket: {
      formCostUnitFormat: '{name} ({costUnit})',
      label: 'Order',
      empty: 'Your basket is empty',
      incompatible: {
        title: 'Incompatible requirement',
        content: '<b>{shortName}</b> is incompatible due to <b>{incompatible}</b>',
      },
      removedIncompatible: {
        title: 'Removed incompatible requirement',
        content: '{shortName} has been removed from the basket because it is incompatible',
      },
      profileDissolved: 'The profile has been dissolved, selected analyses are combined',
      profileDissolvedNewLocalisations: {
        title: 'The profile has been dissolved',
        content: 'Localisations of "{longName}" are taken from "{profileName}"',
      },
      incompatibleCostUnit: {
        title: 'Incompatible cost unit',
        content: 'This requirement has no mapping form for the selected cost unit and will be removed.',
      },
      duplicateRequirements: {
        title: 'Already in basket',
        content: 'This requirement is already in basket as a sub requirement or analyses because of <b>{duplicate}</b>',
      },
      alreadyInBasketRequirements: {
        title: 'Already in basket',
        content: 'The requirement <b>{requirement}</b> is already in basket and is not added again with this profile',
        contentWithProfile: 'The requirement <b>{requirement}</b> is already in basket in the profile <b>{profile}</b> and is not added again with this profile',
      },
      duplicateRemoved: {
        title: 'Removed duplicate in basket',
        content: 'This requirement <b>{shortName}</b> includes <b>{duplicate}</b> as a sub requirement or analyses that was already in basket. It has been removed.',
      },
    },
    profiles: {
      create: {
        title: 'Create Profile',
        notifications: {
          success: 'Profile successfully created',
        },
      },
      update: {
        title: 'Update Profile',
        notifications: {
          success: 'Profile successfully updated',
        },
      },
      withFreeTexts: {
        title: 'Also save all free text for requirements with',
      },
      selectedDiagnoses: 'Edit diagnoses',
      edit: 'Edit Profiles',
      copy: 'Copy Profiles',
      pdfExport: 'Export Profiles',
      name: 'Name',
      color: 'Color',
      controls: {
        copy: {
          tooltip: 'Copy',
          notifications: {
            success: 'Profile copied successfully',
            error: 'An error occurred',
          },
          formNotFound: {
            content: 'The following requirements aren\'t going to be accepted because there is no suitable form for them',
          },
          nameCollision: {
            content: 'There is already a profile with that name',
            question: 'Should it be replaced or renamed to <span style="font-weight: bold;">{newName}</span>',
            controlLabels: {
              replace: 'Replace',
              rename: 'Rename',
            },
          },
          toAllOthers: {
            title: 'For all other cost units',
          },
        },
      },
      labels: {
        pool: 'Pool',
      },
    },
    groupForms: {
      sort: 'Sort group forms',
    },
    barcodes: {
      edit: {
        title: 'Edit barcode',
        placeholder: 'Barcode',
      },
      format: {
        title: 'Barcode format',
        description: 'The barcode must be in a valid format',
      },
    },
    poolSplit: 'Pool Split',
    requirements: 'Requirements',
    poolAllPatients: 'All',
    poolSinglePatients: 'Single',
    costs: 'Costs',
    priceOnApplication: 'Price on application',
    costSeparation: 'thereof {type}',
    costSeparationForm: 'thereof Form',
    formBasePrice: 'Form base price',
    confirmClose: {
      title: 'Confirm close',
      content: 'There are unsaved changes. Do you really want to close the wizard?',
    },
    freeText: {
      noFreetextAvailable: 'No free text available',
    },
    realReRequest: {
      tooltip: 'Re-request',
    },
  },
  controls: {
    changeLog: {
      label: 'Change Log',
      unknownUser: 'Unknown User',
      actions: {
        [OrderChangeAction.AddRequirement]: 'Added requirement <b>{longName}</b>',
        [OrderChangeAction.AddProfileRequirement]: 'Added profile <b>{longName}</b>',
        [OrderChangeAction.RemoveRequirement]: 'Removed requirement <b>{longName}</b>',
        [OrderChangeAction.RemoveProfileRequirement]: 'Removed profile <b>{longName}</b>',
        [OrderChangeAction.CostUnitChanged]: 'Changed cost unit to <b>{costUnit}</b>',
        [OrderChangeAction.OrderChanged]: 'Order data changed',
        [OrderChangeAction.GlobalSettingsChanged]: 'Global settings changed',
        [OrderChangeAction.OfficeDoctorChanged]: 'Doctor set to <b>{doctorGroupOfficeName}</b>',
        [OrderChangeAction.ParametersChanged]: 'Parameters changed',
        [OrderChangeAction.GroupBy]: 'Group by {name}',
        [OrderChangeAction.GroupForm]: 'Group by {name}',
        [OrderChangeAction.SetLastUsedWorkstation]: 'Set last used workstation',
        [OrderChangeAction.SetCostUnitFromPatient]: 'Set CostUnit from patient',
        [OrderChangeAction.SetCostUnitFromPatientHvCode]: 'Set CostUnit from patient HvCode',
        [OrderChangeAction.SetInsuranceNameFromPatient]: 'Set insurance name from patient',
        [OrderChangeAction.Gdt2OA]: 'Gdt2OA',
        [OrderChangeAction.Url]: 'Url',
        [OrderChangeAction.LoadedGdtPatient]: 'Loaded Gdt Patient',
        [OrderChangeAction.ExportWithoutPDF]: 'Exported without PDF',
        [OrderChangeAction.LoadedGdtPatients]: 'Loaded Gdt Patients',
        [OrderChangeAction.LoadedGdtPatientByTimestamp]: 'Loaded Gdt Patient By Timestamp',
        [OrderChangeAction.LoadedPatientByCaseNumber]: 'Loaded Patient By CaseNumber',
        [OrderChangeAction.Saved]: 'Saved',
        [OrderChangeAction.StartEdit]: 'Start Edit',
        [OrderChangeAction.CancelEdit]: 'Cancel Edit',
        [OrderChangeAction.CancelOrders]: 'Cancel Orders',
        [OrderChangeAction.DeleteOrders]: 'Delete Orders',
        [OrderChangeAction.SoftDeleteOrders]: 'SoftDelete Orders',
        [OrderChangeAction.CancelRequirement]: 'Cancel Requirement',
        [OrderChangeAction.Executed]: 'Executed',
        [OrderChangeAction.PrePrinted]: 'PrePrint',
        [OrderChangeAction.Signed]: 'Signed',
        [OrderChangeAction.StartReRequest]: 'Start ReRequest',
        [OrderChangeAction.CancelReRequest]: 'Cancel ReRequest',
        [OrderChangeAction.AcceptReRequest]: 'Accept ReRequest',
        [OrderChangeAction.SendOffReRequest]: 'SendOff ReRequest',
        [OrderChangeAction.LDTExport]: 'LDT Export',
        [OrderChangeAction.HL7Export]: 'HL7 Export',
        [OrderChangeAction.AutoOrderClose]: 'Auto order close',
        [OrderChangeAction.Sent_wpusername]: 'wpusername sent',
      },
    },
    execute: {
      label: 'Execute order',
      labelInLab: 'Sample in lab',
      sampleDate: 'Sample date',
      notifications: {
        success: 'Order executed successfully',
      },
    },
    edit: {
      splitOrderCancelNew: {
        title: 'Split order not editable',
        content: 'Do you want to cancel the orders and edit them as new?',
      },
    },
    save: {
      label: 'Save order',
      labelChange: 'Apply changes',
      saveScheduled: 'Save scheduled',
      notifications: {
        success: 'Order saved',
      },
    },
    clearBasket: {
      label: 'Clear basket',
    },
    cancel: {
      label: 'Cancel',
      confirm: {
        title: 'Confirmation',
        content: 'Do you really want to cancel the order?',
      },
      notifications: {
        success: 'Order canceled',
      },
    },
    exportWithoutPDF: {
      label: 'Export without PDF',
      notifications: {
        success: 'Exported without PDF',
      },
    },
    cancelRequirement: {
      label: 'Cancel requirement',
      isCancelled: 'Cancelled requirement',
      confirm: {
        title: 'Confirmation',
        content: 'Do you really want to cancel this requirement?',
      },
      notifications: {
        success: 'Requirement canceled',
      },
    },
    finish: {
      label: 'Finish',
      notifications: {
        success: 'Order finished',
      },
    },
    prePrint: {
      label: 'Pre-Print',
      notifications: {
        success: 'Order pre print queued',
      },
    },
    schedule: {
      label: 'Schedule',
      form: {
        title: 'Choose date',
        placeholder: 'Scheduled date',
      },
      notifications: {
        success: 'Order scheduled',
      },
    },
    sampleDate: {
      label: 'SampleDate',
      form: {
        title: 'Choose date',
        placeholder: 'Sample date',
      },
    },
    delete: {
      label: 'Delete order',
      confirm: {
        title: 'Confirmation',
        content: 'Do you really want to delete the order?',
      },
      notifications: {
        success: 'Order deleted',
      },
    },
    printBarcode: {
      label: 'Print barcode',
      notifications: {
        success: 'Barcode print queued',
      },
    },
    printAllBarcodes: {
      label: 'Print barcodes',
      notifications: {
        success: 'Barcodes print queued',
      },
    },
    expendExtraDynamicBarcode: {
      label: 'Print extra barcodes',
      notifications: {
        success: 'Extra barcodes print queued',
      },
    },
    printPatientLabel: {
      label: 'Print patient label',
      notifications: {
        success: 'Print queued',
      },
    },
    printOrderForm: {
      label: 'Print order form',
      notifications: {
        success: 'Order form print queued',
      },
    },
    reRequest: {
      label: 'Re-request',
      notifications: {
        success: 'Re-request started successfully',
      },
    },
    sendOffReRequest: {
      label: 'Send off',
      notifications: {
        success: 'Re-request sent off successfully',
      },
    },
    cancelReRequest: {
      label: 'Cancel',
      notifications: {
        success: 'Re-request canceled successfully',
      },
    },
  },
  additionalFields: {
    addPatient: 'Add patient',
    patientData: 'Patient data',
    additionalInformation: 'Additional information',
    insuranceData: 'Insurance Data',
    covid19Info10C: 'Covid 19 Information Muster 10C',
    covid19InfoOegd: 'Covid 19 Information Muster OEGD',
    individualCopyTo: {
      tabTitle: 'Report Copy To',
    },
    patient: {
      insuranceNumber: 'Insurance number',
      cycleDay: 'Cycle day',
      cycleLength: 'Cycle length',
      firstDayLastCycle: 'First day last cycle',
      fetalCrownRumpLength: 'Fetal crown rump length',
      fetalNuchalTranslucency: 'Fetal nuchal translucency',
      pregnancyWeek: 'Pregnancy week',
      pregnancyWeekDay: 'Pregnancy week day',
      austria: {
        employer: 'Employer',
        employerPlace: 'Place of employer',
        chargeType: 'Charge type',
      },
      switzerland: {
        kanton: 'Kanton',
        secondName: 'Second name',
        ahvNr: 'AHV Number',
        billToOther1: 'Invoice recipient line 1',
        billToOther2: 'Invoice recipient line 2',
        billToOther3: 'Invoice recipient line 3',
        reportCopyTo: 'Copy to',
        reportCopyToEmail: 'Copy to',
        reportCopyToEmailTitle: 'Send copy to email',
        reportCopyToEmailFromPatient: 'Use patient email',
        reportCopyToAddressTitle: 'Send copy to address',
        reportCopyToSalutation: 'Salutation/title',
        reportCopyToAddressFromPatient: 'Use patient address',
        reportCopyToSwitch: 'Patient wants to get emails',
        notes: 'Notes',
        globalLocationNumber: 'Global Location Number',
        bag: 'BAG',
        cardNumber: 'Card Number',
        aisNumber: 'AIS Number',
      },
      hungary: {
        insuranceNumber: 'EESzT ID',
        citizen: 'Hungarian Citizen',
        motherLastName: 'Mother\'s last name',
        motherFirstName: 'Mother\'s first name',
        treatmentReason: 'Treatment reason',
        icd1: 'Icd1',
        icd2: 'Icd2',
        icd3: 'Icd3',
        invoice: 'Invoice',
      },
      covid: {
        testNumber: 'Test number',
        testReason: 'Test reason',
        agreed: 'Agreed',
        riskCharacteristicsLocation: 'Risk characteristics location',
        riskCharacteristicsLocationType: 'Risk characteristics location type',
        legalReason: 'Legal reason',
        healthService: 'Health service',
        healthServiceZipCode: 'Health service Zip Code',
        legalOrderBasis: 'Legal order basis',
        kvSpecial: 'KV-Special',
        confirmationDiagnostics: 'PCR confirmation test',
        variantDiagnostics: 'Variant PCR',
        oegdInvoiceTo: 'OEGD invoice to',
      },
    },
    austria: {
      patientTitle: 'Patient',
      insuredTitle: 'Insured Person',
    },
    doctorSelect: 'Doctor',
    doctorAndWorkplace: 'Doctor & Workplace',
    workplace: 'Workplace',
    importantPatientInfo: 'Important patient information',
    pregnancy: 'Pregnancy',
    checkup: 'Checkup',
    invoiceTo: 'Invoice to',
    invoiceToWithValue: 'Invoice to {invoiceTo}',
    invoiceToFromGlobal: 'Set default ({invoiceTo})',
    invoiceToFromGlobalNone: 'Set default (none selected)',
    costUnitIdentification: 'Cost unit identification',
    invoiceToOptions: {
      purchaser: 'Purchaser',
      patient: 'Patient',
      doctor: 'Doctor',
      insurance: 'Insurance',
      other: 'Other',
      defaultFromOrderForm: 'Default order form',
    },
    costUnit: 'Payer/Billing Type',
    insuranceInfo: 'Insurance Info',
    switzerlandInsuranceInfo: 'Insurance Info',
    hvcode: 'HV Code',
    vkat: 'Vkat',
    insuranceName: 'Insurance name',
    orderData: 'Order data',
    scheduledDate: 'Scheduled date',
    priority: 'Priority',
    urgent: 'Urgent',
    emergency: 'Emergency',
    urgentNotification: 'Notification',
    accidentAdditionalInfo: 'Accident additional info',
    infectious: 'Infectious',
    reportCopy: 'Report Copy to',
    hzv: 'HZV',
    quickReportBarcode: 'Quick report barcode',
    primaryCarePhysicianBsnr: 'Primary Care Physician\'s BSNR',
    primaryCarePhysicianLanr: 'Primary Care Physician\'s LANR',
    dmp: 'DMP',
    dmpTypes: {
      [DmpType.Dmp01]: '01',
      [DmpType.Dmp02]: '02',
      [DmpType.Dmp03]: '03',
      [DmpType.Dmp04]: '04',
      [DmpType.Dmp05]: '05',
      [DmpType.Dmp06]: '06',
      [DmpType.Dmp07]: '07',
      [DmpType.Dmp08]: '08',
      [DmpType.Dmp09]: '09',
      [DmpType.Dmp10]: '10',
      [DmpType.Dmp11]: '11',
      [DmpType.Dmp12]: '12',
      [DmpType.Dmp30]: '30',
      [DmpType.Dmp31]: '31',
      [DmpType.Dmp32]: '32',
      [DmpType.Dmp33]: '33',
      [DmpType.Dmp34]: '34',
      [DmpType.Dmp35]: '35',
      [DmpType.Dmp36]: '36',
      [DmpType.Dmp37]: '37',
      [DmpType.Dmp38]: '38',
      [DmpType.Dmp39]: '39',
      [DmpType.Dmp40]: '40',
      [DmpType.Dmp41]: '41',
      [DmpType.Dmp42]: '42',
      [DmpType.Dmp43]: '43',
      [DmpType.Dmp44]: '44',
      [DmpType.Dmp45]: '45',
      [DmpType.Dmp46]: '46',
      [DmpType.Dmp47]: '47',
      [DmpType.Dmp48]: '48',
      [DmpType.Dmp49]: '49',
      [DmpType.Dmp50]: '50',
      [DmpType.Dmp51]: '51',
      [DmpType.Dmp52]: '52',
      [DmpType.Dmp53]: '53',
      [DmpType.Dmp54]: '54',
      [DmpType.Dmp55]: '55',
      [DmpType.Dmp56]: '56',
      [DmpType.Dmp57]: '57',
      [DmpType.Dmp58]: '58',
    },
    dmpSpecialGroup: 'DMP Special Group',
    dmpSpecialGroupTypes: {
      [DmpSpecialGroup.Special04]: '04',
      [DmpSpecialGroup.Special06]: '06',
      [DmpSpecialGroup.Special07]: '07',
      [DmpSpecialGroup.Special08]: '08',
      [DmpSpecialGroup.Special09]: '09',
    },
    insuranceStart: 'Start of insurance period',
    insuranceEnd: 'End of insurance period',
    accountingArea: 'Accounting area',
    accountingAreaTypes: {
      [AccountingArea.Area00]: '00 Primary',
      [AccountingArea.Area01]: '01',
      [AccountingArea.Area02]: '02',
      [AccountingArea.Area03]: '03',
      [AccountingArea.Area04]: '04',
      [AccountingArea.Area05]: '05',
      [AccountingArea.Area06]: '06',
      [AccountingArea.Area07]: '07',
      [AccountingArea.Area08]: '08',
      [AccountingArea.Area09]: '09',
    },
    exceptionalIndication: 'Exceptional indication',
    exceptionalIndicationTypes: {
      [ExceptionalIndication.ExceptionalIndication87777]: '87777',
    },
    patientInsuranceType: 'Insurance type',
    patientInsuranceTypes: {
      [InsuranceType.Type1]: '1 Member',
      [InsuranceType.Type3]: '3 Family insured',
      [InsuranceType.Type5]: '5 Pensioner',
    },
    sktIdentifier: 'SKT',
    wopIdentifier: 'WOP',
    orderReason: 'Order Reason',
    orderReasons: {
      [OrderReason.Cure]: 'Curative',
      [OrderReason.Preventive]: 'Preventive',
      [OrderReason.ESS]: 'ESS',
      [OrderReason.Receipt]: 'Receipt',
    },
    chTreatmentReason: 'Teatment reason',
    chTreatmentReasons: {
      [ChTreatmentReason.Disease]: 'Disease',
      [ChTreatmentReason.Pregnancy]: 'Pregnancy',
      [ChTreatmentReason.Accident]: 'Accident',
      [ChTreatmentReason.Prevention]: 'Prevention',
      [ChTreatmentReason.Disability]: 'Disability',
    },
    accident: 'Accident, accident consequences',
    controlCheckupKnownInfection: 'Control checkup infection',
    _par116b_sgb_v: 'SER',
    _par16_3a_sbg_v: 'according §16 abs 3a SBG V',
    medication: 'Medication',
    'patient.diagnosis': 'Diagnosis',
    freeText: 'Free text',
    mtl: 'Message to laboratory',
    sampleAmount: 'Sample amount',
    samplePeriod: 'Sample period',
    sampleDate: 'Sample date',
    sampleTime: 'Sample time',
    bloodCollectionType: {
      label: 'Blood collection type',
      values: {
        [BloodCollectionType.Fasting]: 'Fasting',
        [BloodCollectionType.Capillary]: 'Capillary',
        [BloodCollectionType.Postprandial]: 'Postprandial',
      },
    },
    splitFreeTexts: 'Free texts',
    splitDiagnoses: 'Diagnoses',
    patientIdentification: 'PID',
    caseNumber: 'Case Number',
    hospitalStation: 'Station',
    hospitalProfession: 'Profession',
    hospitalBedNumber: 'Bed',
    hospitalRoomNumber: 'Room',
    anamnesticInfo: 'Anamnestic info',
    externalOrderInfo: 'External order info',
    beforeAfterDialysis: 'Before / after dialysis',
  },
  requirementOptions: {
    formCostUnitFormat: '{name} ({costUnit})',
    costUnit: {
      title: 'Select cost unit',
      text: 'Please select a cost unit',
      format: '({costUnit})',
    },
    form: {
      title: 'Which form would you like to choose?',
      text: 'This requirement has more than one order form assigned. Please choose one.',
      infoLabel: 'Why do I see this window?',
      infoBody: 'This requirement has more than one order form assigned. Please choose one.',
    },
    analyses: {
      title: 'Which analyses would you like to choose?',
      text: 'This requirement has multiple analyses assigned. Please choose one or more.',
    },
    dynamicMaterials: {
      title: 'How many samples do you need?',
      text: '',
      add: 'Add sample',
      sample: 'Sample #{number}',
    },
    leftRight: {
      title: 'On which side is the sample taken?',
      text: 'Please choose one.',
      label: 'Side: {VALUE}',
      options: {
        [MicrobiologicalLeftRight.Left]: 'Left',
        [MicrobiologicalLeftRight.Right]: 'Right',
      },
    },
    intraoperative: {
      title: 'Is th sample taken intraoperatively?',
      text: 'Please choose one.',
      label: 'Intraoperative: {VALUE}',
      options: {
        no: 'No',
        yes: 'Yes',
      },
    },
    origin: {
      title: 'Where is the origin of the sample?',
      text: 'Please describe the origin.',
      label: 'Origin: {VALUE}',
      emptyLabel: 'Set Origin',
    },
    diagnosis: {
      title: 'Recommended diagnoses',
      text: 'Please select at least one diagnosis.',
    },
    localizations: {
      title: 'Please select the localization',
      text: 'Please select the localization',
      label: 'Localizations: {VALUE}',
      emptyLabel: 'Set localizations',
    },
    freeText: {
      title: '{name}',
      text: 'Please enter a custom text for the selected requirement',
      placeholder: 'Free text',
    },
    freeTextDate: {
      title: '{name}',
      text: 'Please enter a custom date for the selected requirement',
      placeholder: 'Date ({format})',
    },
    freeTextFromBasket: {
      title: '',
      text: 'Please enter a custom text for the requirement',
      placeholder: 'Free text',
    },
    freeTextDateFromBasket: {
      title: '',
      text: 'Please enter a custom date for the requirement',
      placeholder: 'Date ({format})',
    },
    acceptRules: {},
  },
  urgentNotification: {
    urgentTitle: 'Urgent Notification',
    emergencyTitle: 'Emergency Notification',
    description: 'Add optional notifications or leave empty to get notified as agreed with the laboratory.',
    phone: 'Notification phone number',
    fax: 'Notification fax number',
    mail: 'Notification E-mail',
  },
  hints: {
    title: 'Note from laboratory',
    printHeader: 'Note for order {TNR}',
    currDate: 'Print date: {DATE}',
  },
  reportCopyInfo: {
    title: 'Report copy information',
    toEmail: 'Send to E-mail',
    toAddress: 'Send to Address',
  },
  missing: 'Please fill out all mandatory fields',
  fields: {
    [OrderRulesMandatoryField.FirstName]: 'Patient First Name',
    [OrderRulesMandatoryField.LastName]: 'Patient Last Name',
    [OrderRulesMandatoryField.Gender]: 'Patient Gender',
    [OrderRulesMandatoryField.PatientWeight]: 'Patient Weight',
    [OrderRulesMandatoryField.PatientHeight]: 'Patient Height',
    [OrderRulesMandatoryField.Birthday]: 'Birthday',
    [OrderRulesMandatoryField.Country]: 'Country',
    [OrderRulesMandatoryField.ZipCode]: 'Zip Code',
    [OrderRulesMandatoryField.City]: 'City',
    [OrderRulesMandatoryField.Address]: 'Address',
    [OrderRulesMandatoryField.HouseNumber]: 'House Number',
    [OrderRulesMandatoryField.InsuranceNumber]: 'Insurance Number',
    [OrderRulesMandatoryField.InsuranceName]: 'Insurance Name',
    [OrderRulesMandatoryField.Diagnosis]: 'Diagnosis',
    [OrderRulesMandatoryField.CostUnitIdentification]: 'Cost Unit Identification',
    [OrderRulesMandatoryField.AccountingArea]: 'Accounting Area',
    [OrderRulesMandatoryField.InsuranceType]: 'Insurance Type',
    [OrderRulesMandatoryField.WopIdentifier]: 'Wop Identifier',
    [OrderRulesMandatoryField.AisNumber]: 'Ais Number',
    [OrderRulesMandatoryField.Phone]: 'Phone',
    [OrderRulesMandatoryField.Email]: 'Email',
    [OrderRulesMandatoryField.HvCode]: 'HvCode',
    [OrderRulesMandatoryField.VKat]: 'VKat',
    [OrderRulesMandatoryField.LegalOrderBasis]: 'Legal Order Basis',
    [OrderRulesMandatoryField.KvSpecial]: 'KV-Special',
    [OrderRulesMandatoryField.TestNumber]: 'Test Number',
    [OrderRulesMandatoryField.LegalReason]: 'Legal Reason',
    [OrderRulesMandatoryField.ConfirmationDiagnostics]: 'Confirmation Diagnostics',
    [OrderRulesMandatoryField.RiskCharacteristicsLocation]: 'Risk Characteristics Location',
    [OrderRulesMandatoryField.HealthService]: 'Health Service',
    [OrderRulesMandatoryField.HealthServiceZipCode]: 'Health Service Zip Code',
    [OrderRulesMandatoryField.PregnancyWeek]: 'Pregnancy Week',
    [OrderRulesMandatoryField.PregnancyWeekDay]: 'Pregnancy Week Day',
    [OrderRulesMandatoryField.SamplePeriod]: 'Sample Period',
    [OrderRulesMandatoryField.SampleAmount]: 'Sample Amount',
    [OrderRulesMandatoryField.SampleDate]: 'Sample Date',
    [OrderRulesMandatoryField.SampleTime]: 'Sample Time',
    [OrderRulesMandatoryField.InvoiceTo]: 'Invoice To',
    [OrderRulesMandatoryField.Tnr]: 'Barcode',
    [OrderRulesMandatoryField.CostUnit]: 'Cost unit',
    [OrderRulesMandatoryField.AhvNr]: 'AhvNr',
  },
  fieldErrors: {
    [OrderRulesMandatoryField.PatientWeight]: 'Please enter the patient weight in kg optionally with one decimal: (x)xx(.x)',
    [OrderRulesMandatoryField.PatientHeight]: 'Please enter the weight in cm',
    [OrderRulesMandatoryField.PregnancyWeek]: 'Please enter the pregnancy week in numbers',
    [OrderRulesMandatoryField.PregnancyWeekDay]: 'Please enter the pregnancy week day in numbers (1-7)',
    'switzerland.reportCopyToEmail': 'Please enter a correct e-mail address',
  },
  realReRequest: {
    detail: {
      applied: {
        form: {
          label: 'Applied additional requirements',
          columns: {
            requirement: 'Requirement',
            shortName: 'Shortname',
            formName: 'Formname',
            comment: 'comment',
            accepted: 'Accepted',
          },
          button: 'Accept/Reject Additional Requests',
        },
      },
      history: {
        form: {
          label: 'Real re-request history',
        },
        info: {
          titles: {
            laborgruppe: 'Laboratory group; blank if main laboratory',
            used_untids: 'Originally used tests',
            used_tubes: 'Tubes used',
            asids: 'Forms used',
            matids: 'Possible materials',
            anfkeys: 'Possible requirements',
          },
        },
      },
    },
  },
  rules: {
    actions: {
      [OrderRuleActionType.RemoveRequirement]: {
        infoText: 'Requirement {shortName} removed due to rule',
      },
    },
  },
  anamnesis: {
    title: 'Anamnesis Questionnaire',
    titleWithNameAndIndex: 'Anamnesis Questionnaire for {name} (Order #{number})',
    save: 'Save anamnesis',
    submit: 'Submit anamnesis',
  },
  diagnoses: {
    save: 'Save diagnoses',
  },
  sidebar: {
    settings: {
      title: 'Settings',
      tabs: {
        general: 'General',
      },
    },
  },
  preferences: {
    ordersDefaultOrderMode: 'Default order mode',
    ordersDefaultStatusInBookmarked: 'Default status in bookmarked',
    ordersDefaultCompletionOrderBy: 'Order completion list by',
    ordersDetailDefaultGroupBy: 'Default group by requirements',
    ordersDetailDefaultSortBy: 'Default sort by requirements',
    ordersBookmarkedPoolMode: 'Show bookmarked orders as pool',
  },
  duplicatePatient: {
    title: {
      initialVisible: 'Orders for the same patients have been found',
      manualVisible: 'The following orders for the same patients already exist',
    },
    text: {
      initialVisible: 'Choose another order to edit/execute it or click new order to create a new one.',
      manualVisible: 'Choose another order to edit/execute it.',
    },
    close: {
      initialVisible: 'Create new order',
      manualVisible: 'Close',
    },
  },
  accidentAdditionalFields: {
    title: 'Accident additional Fields',
    description: 'Please enter the additional information.',
    accidentOrInsuranceNumber: 'Accident / insurance number',
    accidentDate: 'Notification E-mail',
  },
};

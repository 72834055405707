import { useEffect, useMemo } from 'react';
import { OrderRuleActionType, OrderRulesAddRequirementActionOptions } from 'interfaces/api';
import { useOrderRulesContext } from './OrderRulesProvider';
import { differenceBy, find } from 'lodash';
import { useApi } from 'providers';
import { useCurrentOrder } from 'modules/orders/providers';
import { useOrderWizardParametersSelectors, useUpsertBasket } from 'modules/orders/containers/OrderWizard/providers';
import { usePrevious } from 'react-use';
import { useOfficeDoctorSelectors } from 'modules/orders/providers/OfficeDoctorProvider';

export const useAddRequirement = () => {

  const { getActionsByType } = useOrderRulesContext();
  const currentOrder = useCurrentOrder();
  const upsertBasket = useUpsertBasket();

  const pool = useOrderWizardParametersSelectors.pool();

  const officeDoctor = useOfficeDoctorSelectors.officeDoctor();
  const { status: orderStatus, id: orderId, costUnit } = currentOrder || {};
  const { requirements: { getRequirementsAutocomplete } } = useApi();

  const addRequirementActions = useMemo(() => getActionsByType(OrderRuleActionType.AddRequirement), [getActionsByType]);
  const prevAddRequirementActions = usePrevious(addRequirementActions);
  const newAddRequirementActions = differenceBy(addRequirementActions, prevAddRequirementActions, ({ shortName }) => shortName);

  const addNewRequirements = (actions: OrderRulesAddRequirementActionOptions[]) => {

    actions.forEach(({ shortName, laboratoryGroup }) => {

      getRequirementsAutocomplete({
        shortName,
        costUnit,
        orderStatus,
        orderId,
        pool,
        query: '',
        laboratoryGroup: laboratoryGroup || 'LA',
        aid: officeDoctor.aid,
      }).then((result) => {
        const requirement = find(result.requirements, r => r.entityKey.toUpperCase() === shortName.toUpperCase());
        if (requirement) {
          upsertBasket(requirement);
        }
      });

    });

  };

  useEffect(() => {
    window.setTimeout(() => addNewRequirements(newAddRequirementActions), 100);
  }, [newAddRequirementActions]);

};
